.about-section {
  padding: 20px;
  transition: background-color 2s, color 2s;
}

.about-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.about-photo {
  width: 7vw;
  height: 10vh;
  border-radius: 50%;
  object-fit: cover;
}

.about-text {
  flex: 1;
  /* margin-left: -8vw; */
}

.education-container {
  display: flex;
  flex-direction: column;
}

.education-item {
  margin-bottom: 10px;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skill-category {
  width: 100%;
  max-width: 16vw;
}

.skills-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.cube-container {
  margin-top: -60px;
}

.dark-mode {
  background-color: black;
  color: white;
}

.data-brief {
  font-size: 15px;
}

.vertical-timeline-element-content>p span {
  font-size: 12px;
}

.vertical-timeline-element-date,
.vertical-timeline-element-title,
.vertical-timeline-element-subtitle,
.vertical-timeline-element-date {
  font-size: 13px;
}

.skills-container {
  font-size: 13px;
}

.vertical-timeline {
  width: 95%;
  max-width: 1025px;
  margin: 0 auto;
  position: relative;
  padding: 1em 0;
}

.GPA {
  font-size: 12px;
}

@media (max-width: 768px) and (min-width: 469px) {
  .vertical-timeline {
    padding: 0px;
  }

  h3 {
    margin: 3vh;
  }

  .skill-category {
    flex: 1 1 100px;
  }

  .about-photo {
    margin-top: -10px;
    width: 18vw;
    height: 9vh;
    border-radius: 50%;
    object-fit: cover;
  }

  .data-brief {
    font-size: 12px;
  }

  .vertical-timeline-element-content>p span {
    font-size: 10px;
  }

  .vertical-timeline-element-date,
  .vertical-timeline-element-title,
  .vertical-timeline-element-subtitle {
    font-size: 10px;
  }

  .skills-container {
    font-size: 11px;
  }

  .GPA {
    font-size: 10px !important;
  }
}

@media (max-width: 468px) {
  .vertical-timeline {
    padding: 0px;
  }

  .vertical-timeline-element-content {
    max-height: 90px !important;
    padding-top: 0px;
  }

  h3 {
    margin: 3vh;
  }

  .skill-category {
    flex: 1 1 50px;
  }

  .about-photo {
    margin-top: -10px;
    width: 14vw;
    height: 6.5vh;
    border-radius: 50%;
    object-fit: cover;
  }

  .data-brief {
    font-size: 10px;
  }

  .vertical-timeline-element-content>p span {
    font-size: 9px;
  }

  .vertical-timeline-element-date,
  .vertical-timeline-element-title,
  .vertical-timeline-element-subtitle {
    font-size: 9px;
  }

  .skills-container {
    font-size: 9px;
    margin-top: 15px;
  }

  .GPA {
    font-size: 9px !important;
  }
}

@media (max-width: 468px) and (max-height: 668px) {
  h3 {
    margin: 0px !important;
  }
}
