.home-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
}

.home-content {
  display: flex;
  justify-content: space-between;
  align-items: inherit;
  width: 100%;
  height: 100%;
}

.home-title {
  display: flex;
  flex-direction: column;
  font-size: 5vh;
  justify-content: space-around;
  margin-left: 2vw;
  margin-top: 0px;
}

.home-subtitle {
  font-size: 1.5rem;
  color: #666;
}

.home-photo {
  width: 38rem;
  height: 46rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5vw;
}

.intro {
  margin: 0px
}

.name {
  font-size: 5vw;
}

.brief {
  margin: 10px;
  font-size: 2vh;
}

@media only screen and (width<=1024px) {

  .home-photo {
    width: 19rem;
    height: 23rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5vw;
  }
}


@media (max-width: 767px) {

  .home-photo {
    width: 45vw;
    height: 27vh;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 1vw;
  }
}